import { Binder } from '@canalplus/one-navigation';
import { ProgramGuideRubric } from '@dce-front/hodor-types/modules/page_container_content/electronic_program_guide/definitions';
import classNames from 'classnames';
import { useCallback, useContext } from 'react';
import { MIDDLEWARE_STRATE } from '../../../../helpers/oneNavigation/middleware';
import { EPGContext } from '../../store/context';
import ContentRowGuide from '../ContentRowGuide/ContentRowGuide';
import styles from './DaySelector.css';

export type DaySelectorProps = {
  rubrics: ProgramGuideRubric[];
};

/**
 * DaySelector
 *
 * Displays some day items horizontally
 * with possibiliy to scroll inside
 *
 * @param rubrics                  Array of days to be displayed
 */

function DaySelector({ rubrics }: DaySelectorProps): JSX.Element {
  const {
    activeDayIndex,
    isFetchingEPGGrid,
    isFetchingTimeslices,
    updateActiveDayIndex,
    updateSelectedDayUrl,
  } = useContext(EPGContext);

  const isDaysDisabled = isFetchingEPGGrid || isFetchingTimeslices;

  const handleOnClick = useCallback(
    (rubric: ProgramGuideRubric) => {
      updateActiveDayIndex(rubric.day);
      updateSelectedDayUrl(rubric.URLPage);
    },
    [updateActiveDayIndex, updateSelectedDayUrl]
  );

  return (
    <Binder middleware={MIDDLEWARE_STRATE}>
      <ContentRowGuide className={styles.daySelector} isHorizontalList>
        {rubrics.map((rubric) => {
          return (
            <li className={styles.daySelector__item} key={`day-${rubric.day}`}>
              <button
                type="button"
                className={classNames(styles.daySelector__button, {
                  [styles['daySelector__button--active']]:
                    rubric.day === activeDayIndex,
                })}
                data-tv-focusmode="attribute"
                onClick={() => handleOnClick(rubric)}
                disabled={isDaysDisabled && rubric.day !== activeDayIndex}
              >
                <span className={styles.daySelector__button__day}>
                  {rubric.displayName}
                </span>
                <span className={styles.daySelector__button__date}>
                  {rubric.subtitle}
                </span>
              </button>
            </li>
          );
        })}
      </ContentRowGuide>
    </Binder>
  );
}

export default DaySelector;
